import revive_payload_client_4sVQNw7RlN from "/workspace/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_KgADcZ0jPj from "/workspace/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_jmwsqit4Rs from "/workspace/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import _0_siteConfig_MwZUzHrRNP from "/workspace/node_modules/nuxt-site-config/dist/runtime/nuxt/plugins/0.siteConfig.js";
import payload_client_yVLowv6hDl from "/workspace/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_bs2bWDGRIE from "/workspace/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_8vK7RkfGxZ from "/workspace/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_UciE0i6zes from "/workspace/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import _64nuxt_scripts_D34jXE4xNh from "/workspace/.nuxt/modules/@nuxt-scripts.mjs";
import plugin_vue3_YdLad5Mpq3 from "/workspace/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.js";
import components_plugin_KR1HBZs4kY from "/workspace/.nuxt/components.plugin.mjs";
import prefetch_client_5tzzN0oIVL from "/workspace/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import switch_locale_path_ssr_5csfIgkrBP from "/workspace/node_modules/@nuxtjs/i18n/dist/runtime/plugins/switch-locale-path-ssr.js";
import i18n_sq1MuCrqbC from "/workspace/node_modules/@nuxtjs/i18n/dist/runtime/plugins/i18n.js";
import _0_darkMode_plugin_client_E3rzpxJscD from "/workspace/src/shared/plugins/0.darkMode.plugin.client.ts";
import _0_trailingSlash_plugin_J7R1KITcvq from "/workspace/src/shared/plugins/0.trailingSlash.plugin.ts";
import _0_vars_client_plugin_H6gq4nUQCX from "/workspace/src/shared/plugins/0.vars.client.plugin.ts";
import _1_analytics_plugin_client_1mc6dp1XEV from "/workspace/src/shared/plugins/1.analytics.plugin.client.ts";
export default [
  revive_payload_client_4sVQNw7RlN,
  unhead_KgADcZ0jPj,
  router_jmwsqit4Rs,
  _0_siteConfig_MwZUzHrRNP,
  payload_client_yVLowv6hDl,
  navigation_repaint_client_bs2bWDGRIE,
  check_outdated_build_client_8vK7RkfGxZ,
  chunk_reload_client_UciE0i6zes,
  _64nuxt_scripts_D34jXE4xNh,
  plugin_vue3_YdLad5Mpq3,
  components_plugin_KR1HBZs4kY,
  prefetch_client_5tzzN0oIVL,
  switch_locale_path_ssr_5csfIgkrBP,
  i18n_sq1MuCrqbC,
  _0_darkMode_plugin_client_E3rzpxJscD,
  _0_trailingSlash_plugin_J7R1KITcvq,
  _0_vars_client_plugin_H6gq4nUQCX,
  _1_analytics_plugin_client_1mc6dp1XEV
]