const FIELD_NAME = 'device-id'

const COOKIES_LIFE = 1000 * 60 * 60 * 24 * 365 * 3

export function deviceId(): string {
  const cookieDeviceId = useCookie(FIELD_NAME, { expires: new Date(Date.now() + COOKIES_LIFE) })
  const localStorageDeviceId = localStorage.getItem(FIELD_NAME)
  const deviceId = cookieDeviceId.value || localStorageDeviceId || uuid()
  localStorage.setItem(FIELD_NAME, deviceId)
  cookieDeviceId.value = deviceId
  return deviceId
}
